.c-sidebar .c-sidebar-brand {
  background: rgb(39, 50, 56);
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: #22386f;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-minimizer:hover {
  background: #22386f;
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-title,
.c-sidebar-nav,
.c-sidebar .c-sidebar-minimizer {
  background: rgb(39, 50, 56);
}

.c-sidebar-nav-link .badge-info {
  background: transparent;
  border: 1px solid #fff;
}

.dashboardIcon {
  position: absolute;
  float: right;
  right: 0px;
}

.adminHeaderDropdown {
  background-color: #22386f;
  color: rgb(237, 237, 237);
  font-weight: 500;
}

.block-reason-instruction {
  color: #8a93a2;
  font-size: 11px;
}

.action {
  color: rgb(46, 117, 209);
}

.action:hover {
  cursor: pointer;
}

.spinner {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 300px;
}

.spinner-file-upload {
  float: right;
  position: absolute;
  /* display: flex !important; */
  /* justify-content: end !important; */
  align-items: flex-end !important;
  /* right: 20px; */
  height: inherit;
  width: inherit;
}

.loginLogoDiv {
  object-fit: contain;
}

.loginLogoImg {
  width: 150px;
  height: 150px;
}

.loginLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customerHeader {
  display: flex;
  justify-content: space-between;
  color: #8a93a2;
}

.customerdetailsHeader {
  display: flex;
  justify-content: space-between;
}

.dashboardCards {
  height: 150px;
  max-width: 50%;
}

.dashboardCards:hover {
  cursor: pointer;
}

.court-Images {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.div-Images {
  position: relative;
  padding: 5px;
  margin-right: 15px;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(238, 238, 238);
  box-shadow: 0 0 5px rgb(102, 118, 102);
}

.div-Images-CIcon {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 2px;
  color: rgb(0, 187, 255);
  background-color: rgb(196, 196, 196);
  border-radius: 50%;
}

.div-Images-CIcon:hover {
  cursor: pointer;
  color: red;
}

.img-court-format {
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.timeKeeperDiv {
  position: absolute;
  z-index: 999;
  bottom: 0px;
  right: 0px;
}

.timekeeper {
  border-top: 1px solid rgb(218, 218, 218);
  text-align: center;
  padding: 10px 0;
  background-color: #f4f4f4;
}

.dateInputField {
  background-color: white !important;
}

.dateInputField-textArea {
  resize: none;
}

.DaysTable {
  width: 100%;
}

.daysDataWidth {
  width: 15%;
}

.passwordToggle {
  right: 35px;
  padding-top: 12px;
}

.passwordToggle::after {
  width: 100px;
}

.addressIcon {
  width: 25px !important;
  height: 25px !important;
}

.addressIcon:hover {
  cursor: pointer;
}

.booking-table-font {
  font-size: 14px !important;
}

.booking-table-font th,
.booking-table-font td {
  padding: 6px !important;
}

.booking-filter-width-date {
  width: 145px !important;
}

.booking-filter-width-input {
  width: 105px !important;
}

.map-popup {
  position: absolute;
  z-index: 99 !important;
  background-color: red;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 80%;
  height: 80%;
}

.google-map-popup {
  position: relative;
  min-height: 70vh;
}

.loc-marker {
  position: fixed;
  top: 42%;
  left: 50%;
  height: 60px;
  width: 60px;
  transform: translate(-50%, -50%);
}

/* GOOGLE MAP MODEL */

.map-modal {
  position: relative;
  width: 100% !important;
  min-height: 75vh !important;
}

.map-modal-address {
  min-height: 30px;
  padding: 0 0 0 15px;
  /* developer css */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
}

.map-modal-address label {
  float: right;
  padding: 10px;
  width: 40px;
  font-size: 16px;
  text-align: center;
  border-radius: 0 4px 0 0;
  margin: 0px;
}

.map-modal-address label:hover {
  cursor: pointer;
  background-color: rgb(0, 148, 148);
}

.map-modal-address h3 {
  float: left;
  padding: 10px;
  font-size: 16px;
  margin: 0px;
}

.sm__modal {
  position: absolute !important;
  top: 60px;
  left: 15px;
  z-index: 100;
  width: 370px;
  height: 40px;
}

.sm__modal input {
  width: 290px;
  height: 100%;
  padding: 1rem;
  border-radius: 4px 0 0 4px;
  border: 1px solid grey;
  transition: 0.3s all;
}

.sm__modal input:focus {
  border-color: #2e75cf;
}

.sm__modal button {
  width: 80px;
  height: 100%;
  /* padding: 1rem; */
  border: 1px solid teal;
  background-color: teal;
  color: #fff;
  border-radius: 0 4px 4px 0;
  transition: 0.3s all;
}

.sm__modal ul {
  background-color: #fff;
  list-style: none;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  max-height: 250px;
  overflow-y: scroll;
  padding-left: 0;
}

.sm__modal::-webkit-scrollbar {
  width: 0px;
}

/* Track */

.sm__modal::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

.sm__modal::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */

.sm__modal::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.sm__modal ul li {
  border-bottom: 1px solid #eee;
  padding: 0.5rem 1rem;
}

.sm__modal ul li:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

/* Custom Input type file */
.import-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.import-file-input {
  width: 135px;
}
.import-file-input::before {
  content: "Upload Excel File";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.import-file-input:hover::before {
  border-color: black;
}
.import-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* Overlay Loading */
.overlay-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  border-radius: 10px;
}
.filter-dropin {
  width: 110px !important;
  border-radius: 5px 0px 0px 5px !important;
  background-color: rgb(49 57 66 / 12%) !important;
}
.search-dropin {
  width: 137px !important;
  border-radius: 0px 5px 5px 0px !important;
}
/* .rewards {
  margin: 1rem 0 1rem;
} */
